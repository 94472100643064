import React,{useState,useEffect} from 'react';
import { from, Observable } from 'rxjs';
import { ISocial } from '~models/social';
//import { SocialService} from '~services/impl/social-service';

export interface SocialBarProps {
  prop?: string;
}

interface ICard {
  url: string;
  cls: string;
  ttl: string;
  acls: string;
  id: string;
}

const IcoSoc: React.FC<{ icon: ICard }> = ({ icon }) => {
  
  // fetchAll().subscribe(
  //   data => {
  //     fileData = data;
  //     console.log('fetchAll: data',data);
  //   }
  // );
  console.log('Build IcoSoc');
  
  icon.cls += " mr-3 text-white";
  let iSoc = React.createElement("i", {className: icon.cls} );
  let aSoc = React.createElement("a", {
    url: icon.url, 
    className: icon.acls, 
    //[data-bs-toggle]: "tooltip",
    title: icon.ttl,
  },iSoc);
  let icoSoc = React.createElement(
    "li", {
      title: icon.ttl, 
      className: 'nav-item nc-nav-item-soc',
      rel:"noreferrer noopener",      
    },aSoc);

  return icoSoc;
}
{/* 
  <li *ngFor="let s of srvSocData" class="nav-item nc-nav-item-soc" title="{{s.ttl}}" data-toggle="tooltip" data-placement="bottom">
    <a class="nav-link" href="{{s.url}}" target="_blank" rel="noreferrer noopener">
     <i class="{{s.cls}}"></i>
    </a>
  </li>
*/}


var fileData: ICard[] = [];

const SocialIcons = () => {
  console.log('Build SocialIcons');
  return fileData.map(icon => {
    return <IcoSoc key={icon.id} icon={icon} />;
  });
};

function fetchAll(): Observable<ISocial[]> {
  return from(
    // @ts-ignore
    import('src/assets/data/social.json').then((module) => {
      const list = (module.default as unknown) as ISocial[] //ContactView[];
      const mappedList: ISocial[] = [];
      list.forEach((e) => {
        mappedList.push(e);
      });
      return mappedList;
    })
  );
}

export function SocialBar(){ //({prop = 'default value'}: SocialBarTopProps) {
  
  fetchAll().subscribe( //srv.fetchAll
    data => {
      fileData = data;
      console.log('fetchAll: data',data);
    }
  );

  const [data, setData] = useState([]);
  const getData=() => {
    fetch('data.json', {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
       .then(function(response) {
       console.log(response)
         return response.json();
       })
      .then(function(myJson) {
        console.log('myJson',myJson);
        setData(myJson)
      });
    }   
    useEffect(()=> {getData()},[])

  return (
    <ul className="navbar-nav d-flex flex-row m-0 p-0">
      {SocialIcons()}
    </ul>
  );
}
