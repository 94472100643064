import React from 'react';

import { ClientIntro } from '../client-intro/client-intro';
import { ClientList } from '../client-list/client-list';
import { Col, Container, Row } from 'react-bootstrap';

export interface ClientsProps {
  prop?: string;
}

export function Clients({prop = 'default value'}: ClientsProps) {
  //return <div classNameName={styles.Clients}>Clients {prop}</div>;
  return (
<Container>
  <div className="csn-client">
    <ClientIntro></ClientIntro>
    <p className="csn-nrm">&nbsp;</p>
    <ClientList></ClientList>
    <p className="csn-nrm">&nbsp;</p>
    <Row>
      <Col className="col-2 text-right">
        <img src="assets/img/vk.jpg" className="nc-hero" alt="Vlad Kon" title="Vlad Kon" />
      </Col>
      <Col className="col-10">
        <p className="csn-nrm">
          Kind Regards,
        </p>
        <p className="csn-nrm">
          Vlad Kon<br/><a href="https://www.linkedin.com/in/vladkon/" 
          target="_blank" rel="noreferrer noopener">https://www.linkedin.com/in/vladkon/</a>
        </p>
        <p className="csn-nrm">&nbsp;</p>
      </Col>
    </Row>
    <p className="csn-nrm">&nbsp;</p>
  </div>
</Container>
  )
}
