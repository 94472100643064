import React,{useState,useEffect} from 'react';
import { from, Observable } from 'rxjs';
import { IClients} from '~models/clients';

export interface ClientListProps {
  prop?: string;
}

function fetchAll(): Observable<IClients[]> {
  return from(
    // @ts-ignore
    import('src/assets/data/client.json').then((module) => {
      const list = (module.default as unknown) as IClients[] 
      const mappedList: IClients[] = [];
      list.forEach((e) => {
        mappedList.push(e);
      });
      return mappedList;
    })
  );
}
var fileData: IClients[] = [];

export function ClientList({prop = 'default value'}: ClientListProps) {
  //return <div class={styles.ClientList}>ClientList {prop}</div>;
  fetchAll().subscribe( //srv.fetchAll
    data => {
      fileData = data;
      console.log('fetchAll: data',data);
      }
  );

  const [data, setData] = useState([]);
  const getData=() => {
    fetch('data.json', {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
       .then(function(response) {
       console.log(response)
         return response.json();
       })
      .then(function(myJson) {
        console.log('myJson',myJson);
        setData(myJson)
      });
    }
    useEffect(()=> {getData()},[])
  /*
 <Row> //*ngFor="let c of cData"
  <Col class="col-1 text-right">
    <a href="{{c.url}}" target="_blank" rel="noreferrer noopener">
      <img src="../assets/img/{{c.img}}" class="nc-client" alt="{{c.nm}}" title="{{c.nm}}" /></a>
  </Col><Col class="col-11 csn-nrm">
    <b>{{c.nm}}</b> ({{c.tm}})
    
    <ul>

      <li class="csn-nrm"> //*ngFor="let d of c.prj"
        <a target="_blank" href="{{d.lnk}}" rel="noreferrer noopener">{{d.ttl}}</a>
        <div *ngIf="d.tech">
          <blockquote  class="csn-nrm"><b>Technologies:</b> {{d.tech}}</blockquote>
        </div>
        <div *ngIf="!d.tech"><br/><br/></div>
      </li>

    </ul></Col><p class="csn-nrm">&nbsp;</p></Row>
  */
  var s:string='';
  for(let i = 0; i < fileData.length; i++) {
    s += '<div class="row"><div class="col-1 text-right">';// fileData[i].nm + "<br>";
    s += '<a href="'+fileData[i].url+'" target="_blank" rel="noreferrer noopener">';
    s += '<img src="assets/img/'+fileData[i].img+'" class="nc-client" alt="'+fileData[i].nm+'" title="'+fileData[i].nm+'" style="width:3rem;height:3rem;" /></a>';
    s += '</div><div class="col-11 csn-nrm">';
    s += '<b>'+fileData[i].nm+'</b> ('+fileData[i].tm+')<ul>';
    for(let j = 0; j < fileData[i].prj.length; j++) {

    s += '<li class="csn-nrm">';
    s += '<a target="_blank" href="'+fileData[i].prj[j].lnk+'" rel="noreferrer noopener">'+fileData[i].prj[j].ttl+'</a>';
    if(fileData[i].prj[j].tech){
      s += '<blockquote  class="csn-nrm"><b>Technologies:</b> '+fileData[i].prj[j].tech+'</blockquote>';
    } else {
      s += '<br/><br/>';
    }
    s += '</li>';
    }
    s += '</ul></div><p class="csn-nrm">&nbsp;</p></div>';

    //console.log (fileData[i].nm);
  }

  return(
    <div className="csn-nrm">
      <div className="csn-nrm" 
        dangerouslySetInnerHTML={{__html: s}} />
    </div>
      )
}
