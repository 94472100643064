import React,{useState,useEffect} from 'react';
import { from, Observable } from 'rxjs';
import { Col, Container, Row } from 'react-bootstrap';
import  { SocialBar } from '../social-bar/social-bar';
import styles from './app-header.scss';
import {NavbarBrand} from '../navbar-brand/navbar-brand';
import { INav } from '~models/nav';
import { AppHeaderNavHrcd } from '../app-header-nav-hrcd/app-header-nav-hrcd';

// export interface AppHeaderProps {
//   prop?: string;
// }

interface ICard {
  url: string;
  cls: string;
  ttl: string;
  acls: string;
  id: string;
}

function fetchAll(): Observable<INav[]> {
  return from(
    // @ts-ignore
    import('src/assets/data/nav.min.json').then((module) => {
      const list = (module.default as unknown) as INav[] 
      const mappedList: INav[] = [];
      list.forEach((e) => {
        mappedList.push(e);
      });
      return mappedList;
    })
  );
}
var fileData: INav[] = [];

//var srvConfigData: Array<IConfig> = [];
//var srvNavData: Array<INav> = [];
var srtData: Array<INav> = [];

var urlRoot: string = "https://newca.com";
var itmHm!: INav;
var chldData: Array<INav> = [];
var prntData: Array<INav> = [];

export function AppHeader() {//prop = 'default value'}: AppHeaderProps(
  //return <div className={styles.AppHeader}>AppHeader {prop}</div>;
  
  fetchAll().subscribe( //srv.fetchAll
    data => {
      fileData = data;
      srtData = data.sort((a, b) => (a.key < b.key ? -1 : 1));
      console.log('Sorted Data:', srtData);

      srtData.forEach((a) => {
        if (a.lvl != 0) {
          chldData.push(a);
        } else {
          if (a.key === 0) itmHm = a;
          else prntData.push(a);
        }
      });
      console.log('Root Data:', itmHm);
      console.log('Parent Data:', prntData);
      console.log('Child Data:', chldData);

      console.log('fetchAll: data',data);
      }
  );
  
  const [data, setData] = useState([]);
  const getData=() => {
    fetch('data.json', {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
       .then(function(response) {
       console.log(response)
         return response.json();
       })
      .then(function(myJson) {
        console.log('myJson',myJson);
        setData(myJson)
      });
    }
    useEffect(()=> {getData()},[])
  
    var s:string = '';
    for(let i = 0; i < prntData.length; i++) { /* *ngFor="let p of prntData"*/
    s += '<li  className="nav-item dropdown">';
    s += '<a id="{prntData[i].id}" className="nav-link dropdown-toggle" href="{urlRoot}{prntData[i].url}/#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">';
    s += '<i className="{prntData[i].ico}"></i>';
    s += '<span className="clearfix d-none d-sm-inline-block">{prntData[i].nm}</span>';
    s += '</a>';
    s += '<div className="dropdown-menu dropdown-menu-center" aria-labelledby="mnuNws">';
    /*
    for(let j = 0; j < chldData.length; j++) { // *ngFor="let c of chldData"

      s += '<span>';
      if(prntData[i].id === chldData[j].pid) {
        s += '<span>';
        s += '<a id="{chldData[j].id}" href="{urlRoot}{chldData[j].url}" className="dropdown-item waves-effect waves-light nc-dropdown">';
        s += '<i className="{chldData[j].ico}"></i>';
        s += '<span className="clearfix d-none d-sm-inline-block">&nbsp;{chldData[j].nm}</span>';
        s += '</a>';
      }
    }  
    s += '</span>';
    */
    s += '</div>';
    s += '</li>';
  }
  console.log('-----------------------------------');
  console.log(s);
  console.log('-----------------------------------');

  return (
    <header className="nc-hdr-doc">
      <nav id="ncNavBar" className="navbar navbar-expand-lg fixed-top navbar-dark text-white ps-5 pe-3">
        {/* *ngFor="let cnf of srvConfigData"*/}
        <Container>          
          <NavbarBrand title='NewCa.com' url='https://newca.com/'></NavbarBrand>
                    
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ncNav" aria-controls="ncNav" aria-expanded="false" aria-label="toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>        
          
          {/*
          <div className="collapse navbar-collapse justify-content-between" id="ncNav">           
            <ul className="nav navbar-nav smooth-scroll nav-flex-icons mr-auto">              
              
              <li className="nav-item">
                <a id="mnuHm" className="nav-link" href="/#intro">
                  <i className="fa-landmark-dome fa-solid"></i>
                  <span className="clearfix d-none d-sm-inline-block">Home</span>
                </a>
              </li>

              { / * <div className="csn-nrm" dangerouslySetInnerHTML={{__html: s}} /> * / }

            </ul>
            <SocialBarTop></SocialBarTop>
          </div> 
          */}
          
          <AppHeaderNavHrcd></AppHeaderNavHrcd>

        </Container>
      </nav>
    </header>
  )
}
