import React from 'react';

export interface NavbarBrandProps {
  title?: string;
  url?: string;
}

export function NavbarBrand({title='', url=''}: NavbarBrandProps) {

return(
<a className="navbar-brand " href="https://newca.com/}" rel="noreferrer noopener">
  <svg viewBox="0 0 16 16" className="nc-logo">
    <g>
      <path d="M 8 0 L 6.707031 2.414062 C 6.558594 2.675781 6.296875 2.652344 6.035156 2.503906 L 5.097656 2.019531 L 5.792969 5.726562 C 5.941406 6.402344 5.46875 6.402344 5.238281 6.109375 L 3.605469 4.28125 L 3.339844 5.210938 C 3.308594 5.332031 3.171875 5.460938 2.972656 5.429688 L 0.90625 4.996094 L 1.449219 6.96875 C 1.5625 7.40625 1.65625 7.589844 1.332031 7.707031 L 0.59375 8.050781 L 4.152344 10.941406 C 4.292969 11.050781 4.363281 11.246094 4.3125 11.425781 L 4 12.449219 C 5.226562 12.308594 6.324219 12.09375 7.550781 11.964844 C 7.660156 11.953125 7.839844 12.128906 7.839844 12.253906 L 7.675781 16 L 8.273438 16 L 8.179688 12.265625 C 8.175781 12.136719 8.339844 11.953125 8.449219 11.964844 C 9.675781 12.09375 10.773438 12.308594 12 12.449219 L 11.6875 11.425781 C 11.636719 11.246094 11.707031 11.050781 11.847656 10.941406 L 15.40625 8.050781 L 14.667969 7.707031 C 14.34375 7.589844 14.4375 7.40625 14.550781 6.96875 L 15.09375 4.996094 L 13.027344 5.429688 C 12.828125 5.460938 12.691406 5.332031 12.660156 5.210938 L 12.394531 4.28125 L 10.761719 6.109375 C 10.53125 6.402344 10.058594 6.402344 10.207031 5.726562 L 10.902344 2.019531 L 9.964844 2.503906 C 9.703125 2.652344 9.441406 2.675781 9.292969 2.414062 " />
    </g>
  </svg>
  <span className="d-inline-block align-top nc-grad fw-bold">{title?'NewCa.com':title}</span>
</a>
  )
}
