import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { SocialBar } from "../social-bar/social-bar";
//import '/dev/CSNet.ca.clients/CSNet.ca.clients-react/csnet-clients-rui/node_modules/mdbreact/dist/css/mdb.css';
//import { Config } from '/dev/CSNet.ca.clients/CSNet.ca.clients-react/csnet-clients-rui/src/models/config';

//import {Helmet} from "react-helmet";

export interface AppFooterProps {
  prop?: string;
  cUrl?: string;
  cEmail?: string;
  //config: Config;
}

//const msRootUrl = "https://newca.com";
//const msUrl = "/pg/f/cnt";
const msEml = "info@email";
const msAddr = "Toronto, ON, CA";
//const msSiteName = "NewCa.com";
//const msSiteDescr = "Canadian Online New and Video Channel";
const msNC1 = "";
const msNC2 = "";
const msNC3 = "";
const msCSN = "";
const msCurrYear = new Date().getFullYear().toString();
const msVer = "2023.02.21.1234";

export function AppFooter({ prop = "default value" }: AppFooterProps) {
  return (
    <footer className="page-footer font-small text-bg-dark pt-0">
      <div className="bg-primary bg-gradient">
        <Container>
          <Row className="py-2 d-flex align-items-center">
            <Col className="col-8 text-center text-md-left mb-3 mb-md-0">
              <h6 className="mb-0 text-white">
                Get connected with us on social networks!
              </h6>
            </Col>
            <Col className="col-4 text-center text-md-right">
              <SocialBar></SocialBar>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt-5 mb-4 text-center text-md-start">
        <Row className="mt-3">
          <Col className="mb-4 col-3">
            <h6 className="text-uppercase font-weight-bold">
              <strong>NewCa.com</strong>
            </h6>
            <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto nc-100" />
            <p>
              {msNC1}
              <br />
              {msNC2}
              <br />
              {msNC3}
            </p>
          </Col>
          <Col className="mb-4 col-5">
            <h6 className="text-uppercase font-weight-bold">
              <strong>CSNet Inc.</strong>
            </h6>
            <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto nc-100" />
            <div>{msCSN}</div>
          </Col>
          <div className="mb-4 col-4">
            <div className="text-uppercase font-weight-bold">
              <strong>Contact</strong>
            </div>
            <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto nc-84 " />
            <Row>
              <Col className="col-4 text-end">
                <i className="fa-lg fa fa-home me-3"></i>
              </Col>
              <Col className="col-8 nc-fw300 text-start">{msAddr}</Col>
            </Row>
            <Row>
              <Col className="col-4 text-end">
                <i className="fa fa-envelope me-3"></i>
              </Col>
              <Col className="col-8 nc-fw300 text-start">
                <a href="{msRootUrl}{msUrl}" rel="noreferrer noopener">
                  {msEml}
                </a>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
      <div className="footer-copyright py-3 text-center">
        Copyright &copy; 1999-{msCurrYear}&nbsp;
        <a href="http://www.newca.com/" rel="noreferrer noopener">
          NewCa.com
        </a>
        , ISSN 1712-7416, at{" "}
        <a href="http://www.csnet.ca/" rel="noreferrer noopener">
          CSNet Inc.
        </a>
        &nbsp; 2006-{msCurrYear}, v.{msVer}. All rights reserved.
      </div>
    </footer>
  );
  //<div className={styles.AppFooter}>AppFooter {prop}</div>;
}
