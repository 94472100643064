import React from 'react';
import { SocialBar } from '../social-bar/social-bar';

export function AppHeaderNavHrcd() {
 return (

<div className="collapse navbar-collapse justify-content-between" id="ncNav">      
  <ul className="nav navbar-nav smooth-scroll nav-flex-icons mr-auto">              
    <li className="nav-item">
      <a id="mnuHm" className="nav-link" href="/#intro">
        <i className="fa-landmark-dome fa-solid"></i>
        <span className="clearfix d-none d-sm-inline-block">Home</span>
      </a>
    </li>
    <li className="nav-item dropdown">
      <a data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle" id="mnuNws" href="#">
        <i className="fa-book-open-reader fa-solid"></i>
        <span className="clearfix d-none d-sm-inline-block">News</span>
      </a>

      <div aria-labelledby="mnuNws" className="dropdown-menu dropdown-menu-center">
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuNwsTop" href="https://newca.com/#hot-news">
          <i className="fa-parachute-box fa-solid"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;Top Stories</span>
        </a>
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuNwsLts" href="https://newca.com/#latest-news">
          <i className="fa-shuttle-space fa-solid"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;Latest News Stories</span>
        </a>
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuNwsArc" href="https://newca.com/arc">
          <i className="fa-solid fa-tags"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;News Archive</span>
        </a>
      </div>
    </li>
    <li className="nav-item dropdown">
      <a data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle" id="mnuMedia" href="#">
        <i className="fa-icons fas"></i>
        <span className="clearfix d-none d-sm-inline-block">Media</span>
      </a>
      <div aria-labelledby="mnuNws" className="dropdown-menu dropdown-menu-center">
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuMediaLts" href="https://newca.com/#galleryset">
          <i className="fa-drum fa-solid"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;Latest Media</span>
        </a>
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuMedGrd" href="https://newca.com/MediaGrd">
          <i className="fa-images fa-regular"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;Media Archive</span>
        </a>
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuVidGrd" href="https://newca.com/VideoGrd">
          <i className="fa-photo-film fa-solid"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;Video Archive</span>
        </a>
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuMedLst" href="https://newca.com/MediaLst">
          <i className="fa fa-list"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;Media Galleries List</span>
        </a>
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuVidLst" href="https://newca.com/VideoLst">
          <i className="fa fa-list-ul"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;Video Galleries List</span>
        </a>
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuMedRev" href="https://newca.com/MediaReviews">
          <i className="fa-chalkboard-user fa-solid"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;Media Reviews</span>
        </a>
      </div>
    </li>

    <li className="nav-item dropdown">
      <a data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle" id="mnuAbout" href="#">
        <i className="fa-people-roof fa-solid"></i>
        <span className="clearfix d-none d-sm-inline-block">About</span>
      </a>
      <div aria-labelledby="mnuNws" className="dropdown-menu dropdown-menu-center">
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuAboutUs" href="https://newca.com/doc/1023">
          <i className="fa-people-group fa-solid"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;About Us</span>
        </a>
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuContact" href="https://newca.com/pg/f/cnt">
          <i className="fa-handshake fa-regular"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;Contact Us</span>
        </a>
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuAdv" href="https://newca.com/pg/f/adv/2766">
          <i className="fa-bullhorn fa-solid"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;Advertisements</span>
        </a>
        <a className="dropdown-item waves-effect waves-light nc-dropdown" id="mnuSupport" href="https://newca.com/doc/333">
          <i className="fa-hand-holding-heart fas"></i>
          <span className="clearfix d-none d-sm-inline-block">&nbsp;Support Us</span>
        </a>
      </div>
    </li>

  </ul>
  <SocialBar></SocialBar>
</div>
 );
}
