import React,{useState,useEffect} from 'react';
import { from, Observable } from 'rxjs';
import { IClientIntro } from '~models/client-intro';

export interface ClientIntroProps {
  prop?: string;
}

var fileData: IClientIntro[] = [];

function fetchAll(): Observable<IClientIntro[]> {
  return from(
    // @ts-ignore
    import('src/assets/data/client.intro.json').then((module) => {
      const list = (module.default as unknown) as IClientIntro[] //ContactView[];
      const mappedList: IClientIntro[] = [];
      list.forEach((e) => {
        mappedList.push(e);
      });
      return mappedList;
    })
  );
}
export function ClientIntro({prop = 'default value'}: ClientIntroProps) {
  //return <div className={styles.ClientIntro}>ClientIntro {prop}</div>;
  fetchAll().subscribe( //srv.fetchAll
    data => {
      fileData = data;
      console.log('fetchAll: data',data);
    }
  );
  
  const [data, setData] = useState([]);
  const getData=() => {
    fetch('data.json', {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
       .then(function(response) {
       console.log(response)
         return response.json();
       })
      .then(function(myJson) {
        console.log('myJson',myJson);
        setData(myJson)
      });
    }
    useEffect(()=> {
      getData()
    }, 
    []    
  )
  var clnStr:string='';
  for(let i = 0; i < fileData.length; i++) {
    clnStr +=fileData[i].txt + "<br>";
    //console.log (fileData[i].txt);
  }
  
  return(
<div className="csn-nrm pe-2 ps-2">
  <div className="csn-nrm" 
    dangerouslySetInnerHTML={{__html: clnStr}} />
</div>
  )
}
